<script lang="ts" setup>
import VueApexCharts from "vue3-apexcharts";
import { getLineChartConfig } from '@/utils/chartHelpers';
import type { AppChartLineData } from '@/types/app-component.types';

interface Props {
  labels: string[]
  series: AppChartLineData[]
  currency?: string;
  title?: string
  width?: number
  height?: number
}

const props = defineProps<Props>()

const chartConfig = computed(() => getLineChartConfig(props.labels, props.title, props.currency))
</script>

<template>
  <VueApexCharts
    :width="width"
    :height="height"
    type="line"
    :options="chartConfig"
    :series="series"
  />
</template>
